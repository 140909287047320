import {lazy} from "react"

export const Routes = [
    {
        path: "/dashboard",
        component: lazy(() => import("../views/dashboard")),
        meta: {
            general: true
        }
    },
    {
        path: "/admin/lists",
        component: lazy(() => import("../views/admins-list")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/admin/login-tow-steps-code",
        component: lazy(() => import("../views/accept-invitation/EnterCodeLogin")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/admin/login-admin",
        component: lazy(() => import("../views/login-admin")),
        layout: "BlankLayout",
        meta: {
            authRoute: true
        }
    },
    {
        path: "/admin/login-admin-tow-steps-code",
        component: lazy(() => import("../views/login-admin/EnterCodeLogin")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: `/verify/:verification_code`,
        component: lazy(() => import("../views/accept-invitation")),
        layout: "BlankLayout",
        meta: {
            authRoute: true
        }
    },
    {
        path: `/forgot-password`,
        component: lazy(() => import("../views/forget-password")),
        layout: "BlankLayout",
        meta: {
            authRoute: true
        }
    },
    {
        path: "/admin/sumsub-kyc",
        component: lazy(() => import("../views/sumsub-kyc")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/users/list",
        component: lazy(() => import("../views/users-list")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/kyc/users/pending",
        component: lazy(() => import("../views/kyc-pending-users-list")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/users/blacklist",
        component: lazy(() => import("../views/blacklist-users-list")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/kyc/admins/pending",
        component: lazy(() => import("../views/kyc-pending-admins-list")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/users/activity",
        component: lazy(() => import("../views/dashboard")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/exchange",
        component: lazy(() => import("../views/dashboard")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/currencies",
        component: lazy(() => import("../views/dashboard")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/orders",
        component: lazy(() => import("../views/dashboard")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/trading",
        component: lazy(() => import("../views/dashboard")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/pendig-withdrawls",
        component: lazy(() => import("../views/dashboard")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/deposits-history",
        component: lazy(() => import("../views/dashboard")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/withdrawls-history",
        component: lazy(() => import("../views/dashboard")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/trading-activities",
        component: lazy(() => import("../views/dashboard")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/wallets",
        component: lazy(() => import("../views/dashboard")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/fees",
        component: lazy(() => import("../views/dashboard")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/binance-wallets",
        component: lazy(() => import("../views/dashboard")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/admin/activities",
        component: lazy(() => import("../views/dashboard")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/interface-theme",
        component: lazy(() => import("../views/dashboard")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    }
]
