import React from "react"
import {Bell, Briefcase, Shield, Home, Lock, Settings, User, Users, UserCheck, Play} from "react-feather"

export const Navigation = [
    {
        id: 'scrapingJops',
        title: 'user.nav.scrapingJobs',
        icon: <Play/>,
        navLink: '/scraping-jobs/lists',
        action: 'call',
        resource: 'NoPermissionCode'
    }
]
