import {API, API_WithSwal} from '../../utility/API'
import {_setAPIToken} from '../../utility/Utils'
import _ from "lodash"
import {getAuth, signInWithPhoneNumber} from "firebase/auth"
// import axios from "axios"
import { store } from "@fwsrc/redux/storeConfig/store"

//************************************//
export const _login = ({verification_code, phone, email, password}, callback, callbackErr) => {
    API.post('admin/accept-invitation', {verification_code, phone, email, password})
        .then(function (res) {
            callback(res)
        })
        .catch(function (res) {
            callbackErr(res)
        })
}

//************************************//
export const _loginOtp = ({email, password}, callback, callbackErr) => {
    API.post('admin/v1/login', {email, password})
        .then(function (res) {
            callback(res)
        })
        .catch(function (res) {
            callbackErr(res)
        })
}

//************************************//

export const _loginWithOtp = (id_token, userid) => {
    API.post('/v1/auth/login', {id_token, userid})
        .then(function ({data}) {
            console.log(data, 'Api data')
        })
        .catch(function (res) {
            console.log(res, 'Api res')
        })
}

//************************************/

export const _autoLogin = (dispatch, ability, callback) => {
    const storageUserToken = JSON.parse(localStorage.getItem('USER_TOKEN'))
    const sessionUserToken = JSON.parse(sessionStorage.getItem('USER_TOKEN'))
    let user = (sessionUserToken || false)
    if (user && user.token && user.user) {
        _setAPIToken(user.token)
        // dispatch({type:"USER_LOGIN", userData:user.user, token:user.token})
        // if (user.user.abilities) {
        //   ability.update(user.user.abilities)
        // }
        API.get('admin/info', {email: user.email, token: user.token})
            .then(function (res) {
                if (res.data.token) {
                    const {data} = res
                    sessionStorage.setItem("USER_TOKEN", JSON.stringify({
                        email: user.email,
                        token: data.token,
                        user: data.user
                    }))
                    _setAPIToken(data.token)
                    dispatch({type: "USER_LOGIN", userData: data.user, token: data.token})
                    if (data.user.abilities) {
                        ability.update(data.user.abilities)
                    }
                }
                callback()
            })
            .catch(function (res) {
                localStorage.removeItem('USER_TOKEN')
                sessionStorage.removeItem('USER_TOKEN')
                callback()
            })
    } else if (user = (storageUserToken || false)) {
        if (user && user.token && user.email) {
            _setAPIToken(user.token)
            API.get('admin/info', {email: user.email, token: user.token})
                .then(function (res) {
                    if (res.data.token) {
                        const {data} = res
                        sessionStorage.setItem("USER_TOKEN", JSON.stringify({
                            email: user.email,
                            token: data.token,
                            user: data.user
                        }))
                        _setAPIToken(data.token)
                        dispatch({type: "USER_LOGIN", userData: data.user, token: data.token})
                        if (data.user.abilities) {
                            ability.update(data.user.abilities)
                        }
                    }
                    callback()
                })
                .catch(function (res) {
                    localStorage.removeItem('USER_TOKEN')
                    sessionStorage.removeItem('USER_TOKEN')
                    callback()
                })
        }
    } else {
        callback()
    }
}

//***************** Admins *******************//

export const _addAdmin = (data, callback, callbackErr) => {
    API_WithSwal.post(`/admins`, data)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//************************************//
export const _editAdminInfo = (data, callback, callbackErr) => {
    API_WithSwal.put(`/admins/${data.id}`, data)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//************************************//
export const _deleteAdmin = (id, callback) => {
    API.delete(`/admins/${id}`)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function ({data}) {
        })
}
//************************************//
export const _approveAdmin = (id, callback) => {
    API.delete(`admin/kyc/approve/${id}`)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function ({data}) {
        })
}
//************************************//
export const _declineAdmin = (id, callback) => {
    API.delete(`admin/kyc/decline/${id}`)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function ({data}) {
        })
}

//************************************//
export const _disableAdmin = (id, callback) => {
    API.delete(`admin/disable/${id}`)
      .then(function ({data}) {
          callback(data)
      })
      .catch(function ({data}) {
      })
}

//************************************//
export const _enableAdmin = (id, callback) => {
    API.delete(`admin/enable/${id}`)
      .then(function ({data}) {
          callback(data)
      })
      .catch(function ({data}) {
      })
}
//************************************//
export const _approveUser = (id, callback) => {
    API.delete(`user/kyc/approve/${id}`)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function ({data}) {
        })
}
//************************************//
export const _declineUser = (id, callback) => {
    API.delete(`user/kyc/decline/${id}`)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function ({data}) {
        })
}

//************************************//
export const _enableUser = (id, callback) => {
    API.delete(`user/enable/${id}`)
      .then(function ({data}) {
          callback(data)
      })
      .catch(function ({data}) {
      })
}

//************************************//
export const _disableUser = (id, callback) => {
    API.delete(`user/disable/${id}`)
      .then(function ({data}) {
          callback(data)
      })
      .catch(function ({data}) {
      })
}

//************************************//
export const _blockUser = (id, callback) => {
    API.delete(`user/block/${id}`)
      .then(function ({data}) {
          callback(data)
      })
      .catch(function ({data}) {
      })
}

//************************************//
export const _unblockUser = (id, callback) => {
    API.delete(`user/unblock/${id}`)
      .then(function ({data}) {
          callback(data)
      })
      .catch(function ({data}) {
      })
}
//************************************//
export const _getAllAdminsWithQ = async (q = '') => {
    const {data} = await API.get('admin/getAllAdminsWithQ', {
        params: {
            limit: 20,
            q
        }
    })
    return _.map(data.municipalities, (v, k) => {
        return {value: v.id, label: v.name}
    })
}

//************************************//
export const _getAllRolesWithQ = async (q = '') => {
    const {data} = await API.get('getAllRolesWithQ', {
        params: {
            limit: 20,
            q
        }
    })
    return _.map(data.roles, (v, k) => {
        return {value: v.id, label: v.name.replace("_", " ")}
    })
}

//************************************//
//************************************//
//************************************//
//************************************//
//************************************//
//************************************//
//************************************//
//************************************//
//************************************//
//************************************//
//************************************//
//************************************//
export const _loginTwoFactor = ({phone, email, password, id_token}, callback, callbackErr) => {
    API.post('admin/v1/login/verify-2fa', {phone, email, password, id_token})
        .then(function (res) {
            callback(res)
        })
        .catch(function ({data}) {
            callbackErr(data)
        })
}
export const _verifyFirstAdminLogin = (data, callback, callbackErr) => {
    API.post('verify-admin', data)
        .then(function (res) {
            callback(res)
        })
        .catch(function ({data}) {
            callbackErr(data)
        })
}
//************************************//
export const _forgetPassword = ({email}, callback, callbackErr) => {
    API.post('admin/forgot-password', {email})
        .then(function ({data}) {
            callback(data)
        })
        .catch(function ({data}) {
            callbackErr(data.data)
        })
}
//************************************//
export const _logout = () => {
    return dispatch => {
        // ** Remove user, accessToken & refreshToken from localStorage
        localStorage.removeItem('USER_TOKEN')
        sessionStorage.removeItem('USER_TOKEN')
        _setAPIToken('')
        dispatch({type: 'USER_LOGOUT'})
    }
}
//************************************//
export const _getMyProfile = (dispatch) => {
    API.get('/user/account/my-profile')
        .then(function ({data}) {
            const userToken = JSON.parse(sessionStorage.getItem('USER_TOKEN'))
            userToken.user = {...userToken.user, ...data.basic_info}
            sessionStorage.setItem("USER_TOKEN", JSON.stringify(userToken))
            dispatch({type: 'USER_MY_PROFILE', data})
        })
        .catch(function (res) {
        })
}
//************************************//
export const _changeBasicInfo = (data, callback, callbackErr) => {
    return dispatch => {
        API.post('/user/account/change-basic-info', data)
            .then(function ({data}) {
                _getMyProfile(dispatch)
            })
            .catch(function ({data}) {
                callbackErr(data.data)
            })
    }

}
//************************************//
export const _changeGeneralInfo = (data, callback, callbackErr) => {
    return dispatch => {
        API.post('/user/account/change-general-info', {...data})
            .then(function ({data}) {
                _getMyProfile(dispatch)
            })
            .catch(function ({data}) {
                callbackErr(data.data)
            })
    }

}

function __getAccessToken (externalUserId, levelName, ttlInSecs) {
    const config = {}
    console.log("Creating an access token for initializng SDK...")
    const method = 'post'
    config.method = method
    const crypto = require('crypto')
    const url = `/resources/accessTokens?userId=${externalUserId}&levelName=${levelName}`
    config.baseURL = 'https://api.sumsub.com'
    config.url = url
    const ts = Math.floor(Date.now() / 1000)
    const signature = crypto.createHmac('sha256', "GKE7CGic9sSLZABT54BdwAC693vxqUyt")
    console.log(signature, 'signature')
    signature.update(ts + config.method.toUpperCase() + config.url)
    console.log(ts, 'ts')
    console.log(ts.toString(), 'ts String')
    console.log(signature, 'signature after update')
    console.log(signature.digest('hex'), 'final signature')
    const headers = {
        'X-App-Token': "sbx:NqFv1MGHVzPiePz2Q2ItEGAI.xtU5pYLs3bRu6izOgz5YndfzLAa16F3R",
        'X-App-Access-Sig': signature.digest('hex'),
        'X-App-Access-Ts': ts,
         Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Methods' : '*'
    }
    config.headers = headers
    config.data = null
    console.log(config, "config")

    return config
}
export async function main() {
    const axios = require('axios')
    const externalUserId = `random-JSToken-${  Math.random().toString(36).substr(2, 9)}`
    const levelName = 'basic-kyc-level'
    console.log("External UserID: ", externalUserId)
    const response = await axios(__getAccessToken(externalUserId, levelName, 600))
        .then(function (response) {
            console.log("Response:\n", response.data)
            return response
        })
        .catch(function (error) {
            console.log("Error:\n", error.response)
        })
}
export const _getAccessToken = async () => {
   const result = await API.post('/generate-admin-kyc-token')
       const res = result
    console.log(res.data.token, "res token")
        store.dispatch({
            type: "ACCESS_TOKEN",
            data: res
        })
    return res
}
