import {lazy} from "react"

export const Routes = [
    {
        path: "/scraping-jobs/lists",
        component: lazy(() => import("../views/scraping-jobs-list")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    }
]
