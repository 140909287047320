const PluggedInModules = {}

try {
    PluggedInModules['user'] = {path: require('@devModules/user'), enabled: true, enableRoutes: false, enableNavigations: false} // user Module ()
} catch (e) {
    // console.error('no User Module', e)
}

try {
    PluggedInModules['scraping'] = {path: require('@devModules/scraping'), enabled: false, enableRoutes: false, enableNavigations: false} // scraping Module ()
} catch (e) {
    // console.error('no scraping Module', e)
}

try {
    PluggedInModules['wenbit'] = {path: require('@devModules/wenbit'), enabled: true, enableRoutes: true, enableNavigations: true} // wenbit Module ()
} catch (e) {
    // console.error('no scraping Module', e)
}

try {
    PluggedInModules['rolespermissions'] = {path: require('@devModules/rolespermissions'), enabled: true, enableRoutes: true, enableNavigations: true} // Roles & Permissions Module ()
} catch (e) {
    // console.error('no User Module', e)
}

export default PluggedInModules
