import React from "react"
import {Circle, Home, Lock, LogIn, Settings, TrendingUp, Users} from "react-feather"
import {FaBitcoin, FaCalculator} from "react-icons/all"

export const Navigation = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        icon: <Home />,
        navLink: '/dashboard',
        action: 'call',
        resource: 'DASHBOARD_VIEW_PAGE'
    },
    // {
    //     id: 'checkKyc',
    //     title: 'wenbit.nav.kycCheck',
    //     icon: <Settings/>,
    //     navLink: '/admin/sumsub-kyc',
    //     action: 'call',
    //     resource: 'NoPermissionCode'
    // },
    {
        id: 'users',
        title: 'Users',
        icon: <Users />,
        children:[
            // {
            //     id: 'pendingAdmins',
            //     title: 'wenbit.nav.pendingAdmins',
            //     icon: <Circle />,
            //     navLink: '/kyc/admins/pending',
            //     action: 'call',
            //     resource: 'NoPermissionCode'
            // },
            {
                id: 'usersList',
                title: 'wenbit.nav.usersList',
                icon: <Circle />,
                navLink: '/users/list',
                action: 'call',
                resource: 'USER_VIEW_LIST'
            },
            {
                id: 'pendingUsers',
                title: 'KYC Applications',
                icon: <Circle />,
                navLink: '/kyc/users/pending',
                action: 'call',
                resource: 'USER_PENDING_KYC_APPLICATION'
            },
            {
                id: 'blackList',
                title: 'Blacklist',
                icon: <Circle />,
                navLink: '/users/blacklist',
                action: 'call',
                resource: 'USER_VIEW_BLACKLIST'
            },
            {
                id: 'usersActivity',
                title: 'Users Activity',
                icon: <Circle />,
                navLink: '/users/activity',
                action: 'call',
                resource: 'USER_VIEW_ACTIVITY'
            }
        ]
    },
    {
        id: 'exchange',
        title: 'Exchange',
        icon: <TrendingUp />,
        navLink: '/exchange',
        children: [
            {
                id: 'currencies',
                title: 'Currencies',
                icon: <Circle />,
                navLink: '/currencies',
                action: 'call',
                resource: 'EXCHANGE_VIEW_CURRENCIES'
            },
            {
                id: 'orders',
                title: 'Orders',
                icon: <Circle />,
                navLink: '/orders',
                action: 'call',
                resource: 'EXCHANGE_VIEW_ORDERS'
            }
        ]
    },
    {
        id: 'trading',
        title: 'Trading',
        icon: <FaCalculator />,
        navLink: '/trading',
        children: [
            {
                id: 'pendigWithdrawls',
                title: 'Pending Withdrawls',
                icon: <Circle />,
                navLink: '/pendig-withdrawls',
                action: 'call',
                resource: 'TRADING_VIEW_PENDING_WITHDRAWLS'
            },
            {
                id: 'deposits',
                title: 'Deposits',
                icon: <Circle />,
                navLink: '/deposits-history',
                action: 'call',
                resource: 'TRADING_VIEW_DEPOSITS'
            },
            {
                id: 'withdrawlsHistory',
                title: 'Withdrawls History',
                icon: <Circle />,
                navLink: '/withdrawls-history',
                action: 'call',
                resource: 'TRADING_VIEW_WITHDRAWL_HISTORY'
            },
            {
                id: 'tradingActivities',
                title: 'Trading Activities',
                icon: <Circle />,
                navLink: '/trading-activities',
                action: 'call',
                resource: 'TRADING_VIEW_TRADING_ACTIVITY'
            }
        ]
    },
    {
        id: 'operationsTab',
        title: 'Operations',
        icon: <FaBitcoin />,
        children: [
            {
                id: 'blockchains',
                title: 'Blockchains',
                navLink: '/blockchains',
                icon: <Circle />,
                action: 'call',
                resource: 'OPERATIONS_VIEW_BLOCKCHAINS'
            },
            {
                id: 'wallets',
                title: 'Wallets',
                navLink: '/wallets',
                icon: <Circle />,
                action: 'call',
                resource: 'OPERATIONS_VIEW_WALLETS'
            },
            {
                id: 'fees',
                title: 'Fees',
                navLink: '/fees',
                icon: <Circle />,
                action: 'call',
                resource: 'NoPermissionCode'
            },
            {
                id: 'binanceWallets',
                title: 'Binance Wallets',
                navLink: '/binance-wallets',
                icon: <Circle />,
                action: 'call',
                resource: 'OPERATIONS_VIEW_FEES'
            }
        ]
    },
    {
        id: 'Settings',
        title: 'Settings',
        icon: <Settings />,
        children: [
            {
                id: 'adminsList',
                title: 'Administrators',
                icon: <Circle/>,
                navLink: '/admin/lists',
                action: 'call',
                resource: 'SETTINGS_VIEW_ADMIN_LIST'
            },
            {
                id: 'administratorsActivities',
                title: 'Administrators Activities',
                icon: <Circle/>,
                navLink: '/admin/activities',
                action: 'call',
                resource: 'SETTINGS_VIEW_ADMIN_ACTIVITY'
            },
            {
                id: 'interfaceTheme',
                title: 'Interface Theme',
                icon: <Circle/>,
                navLink: '/interface-theme',
                action: 'call',
                resource: 'SETTINGS_VIEW_INTERFACE_THEME'
            }
        ]
    }

]
